<script setup lang="ts">
const { t } = useI18n()
useSeoMeta({
	title: () => t('site.meta.title'),
	ogTitle: () => t('site.meta.title'),
	description: () => t('site.meta.description'),
	ogDescription: () => t('site.meta.description'),
	ogImage: 'https://jokevink.nl/images/JokeVink_ogImage.webp',
	twitterCard: 'summary_large_image',
})
</script>

<template>
	<NuxtRouteAnnouncer />
	<NuxtLayout>
		<NuxtPage />
	</NuxtLayout>
</template>
