import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
export default [
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/opt/build/repo/pages/about.vue")
  },
  {
    name: "about___nl___default",
    path: "/over",
    component: () => import("/opt/build/repo/pages/about.vue")
  },
  {
    name: "about___nl",
    path: "/nl/over",
    component: () => import("/opt/build/repo/pages/about.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/opt/build/repo/pages/contact.vue")
  },
  {
    name: "contact___nl___default",
    path: "/contact",
    component: () => import("/opt/build/repo/pages/contact.vue")
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/opt/build/repo/pages/contact.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___nl___default",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/opt/build/repo/pages/index.vue")
  }
]