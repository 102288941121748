import revive_payload_client_a9woUwkKpD from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ayrnf2htxypccf34ijlqgiekda/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_U4eaPE2s6G from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ayrnf2htxypccf34ijlqgiekda/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_f8XvVsYL1T from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ayrnf2htxypccf34ijlqgiekda/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6D5A4IEvKe from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ayrnf2htxypccf34ijlqgiekda/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qt1eZUWUai from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ayrnf2htxypccf34ijlqgiekda/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_1DxtVHyXEO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ayrnf2htxypccf34ijlqgiekda/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4oNcOoNKmU from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ayrnf2htxypccf34ijlqgiekda/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_CRkxJ22yq5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ayrnf2htxypccf34ijlqgiekda/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_jVLfBSm2Og from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ipqiXPJvN0 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_rjzuUVPZYo from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.6_terser@5.36.0__vue@3.5.12_typescript@5.6.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
export default [
  revive_payload_client_a9woUwkKpD,
  unhead_U4eaPE2s6G,
  router_f8XvVsYL1T,
  payload_client_6D5A4IEvKe,
  navigation_repaint_client_qt1eZUWUai,
  check_outdated_build_client_1DxtVHyXEO,
  chunk_reload_client_4oNcOoNKmU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CRkxJ22yq5,
  switch_locale_path_ssr_jVLfBSm2Og,
  i18n_ipqiXPJvN0,
  plugin_rjzuUVPZYo
]